




























import { Component, Vue, Watch } from "vue-property-decorator";
import BopService from "@/services/BopService";
import { BootstrapVueIcons } from "bootstrap-vue";
import { BopModelEnum, BopStatus } from "@/utils/Enums";

document.title = "Arkema Touch";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: { Table: () => import("@/components/Shared/Table/Table.vue") },
})
export default class Accueil extends Vue {
  private url: string = "";
  public data: Data = {
    columns: [],
    rows: [],
  };
  // Voir message commit poour plus de précisions

  public test: string = this.$t("translations.search").toString();
  private get language(): string {
    return this.$i18n.locale;
  }
  /**
   * #####
   * Methods
   * #####
   */
  private async modify(data) {
    //this.$store.commit("changeBop", await BopService.getBop(data.data.id));
    const key = data.data.bopStatusKey;
    const model = data.data.bopModelKey;
    switch (key) {
      case BopStatus[BopStatus.Initial]:
        this.$router.push({
          name: "BopSetupUpdate",
          params: { bopId: data.data.id },
          query: { index: "0" },
        });
        break;
      case BopStatus[BopStatus.WaitingForValidation]:
        if (model === BopModelEnum[BopModelEnum.Echafaudage]) {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "5" },
          });
        } else {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "4" },
          });
        }
        break;
      case BopStatus[BopStatus.AcceptLunchWork]:
        if (model === BopModelEnum[BopModelEnum.Echafaudage]) {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "6" },
          });
        } else {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "5" },
          });
        }
        break;
      case BopStatus[BopStatus.Finished]:
        if (model === BopModelEnum[BopModelEnum.Echafaudage]) {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "6" },
          });
        } else {
          this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId: data.data.id },
            query: { index: "5" },
          });
        }
        break;
      default:
        this.$router.push({
          name: "BopSetupUpdate",
          params: { bopId: data.data.id },
          query: { index: "0" },
        });
    }
  }

  private loadData() {
    const data: Data = {
      columns: [
        {
          dataClass: "bop",
          name: "editable",
          sortField: "name",
          title: this.$t("bop.name").toString(),
          property: "name",
          isNotEditable: true,
        },
        {
          name: "status",
          sortField: "bopStatusName",
          title: this.$t("bop.status").toString(),
          property: "bopStatusName",
          isNotEditable: true,
        },
        {
          name: "editable",
          sortField: "orderGiverUserFullName",
          title: this.$t("bop.orderGiver").toString(),
          property: "orderGiverUserFullName",
          isNotEditable: true,
        },
        {
          name: "editable",
          sortField: "bopModelName",
          title: this.$t("bop.bopModelName").toString(),
          property: "bopModelName",
          isNotEditable: true,
        },
        {
          name: "editable",
          sortField: "totalPoints",
          title: this.$t("bop.totalPoints").toString(),
          property: "totalPoints",
          isNotEditable: true,
        },
      ],
      rows: [],
    };
    this.data = data;
    this.url = BopService.getSummaryUrl(this.language);
  }
  /**
   * #####
   * Hooks
   * #####
   */
  private created() {
    this.loadData();
  }

  /**
   * #####
   * Watchers
   * #####
   */
  @Watch("language")
  onLanguageChange() {
    this.loadData();
  }
}
