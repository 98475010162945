//key must be the same as the value
export enum RubriqueEnum {
  OperationPrincipal = "OperationPrincipal",
  OperationDiverse = "OperationDiverse",
  CoefficientDimensions = "CoefficientDimensions",
  Exceptions = "Exceptions",
  ReferenceFourniture = "ReferenceFourniture",
  ComplementsCoutsSupp = "ComplementsCoutsSupp",
  ImportBopModel = "ImportBopModel"
}
export enum OperationType {
  OperationPrincipal = 1,
  OperationDiverse = 2,
  OperationSupplementaire = 3,
}
export enum CoefficientTypeEnum {
  T0 = 0,
  T1 = 1,
  T2 = 2,
  T3 = 3,
  T4 = 4,
  T5 = 5,
  CoefficienSupp = -1,
}
export enum BopModelEnum {
  CalorifugageTuyauterie = "CalorifugageTuyauterie",
  TuyauterieMetalique = "TuyauterieMetallique",
  SerrurerieCharpentes = "SerrurerieCharpentes",
  PeintureIndustrielle = "PeintureIndustrielle",
  Echafaudage = "Echafaudage",
  CalorifugageAppareil = "CalorifugageAppareil",
  GenieCivil = "GenieCivil",
  NettoyageIndustrielle = "NettoyageIndustriel",
  TuyauteriePlastique = "TuyauteriePlastique",
  ElectriciteInstrumentation = "ElectriciteInstrumentation",
}
export enum UserProfilEnum {
  arkemaUser = "arkemaUser",
  externalUser = "externalUser",
}
export enum UserRoleEnum {
  orderGiver = "orderGiver",
  siteAdministrator = "siteAdministrator",
  countryAdministrator = "countryAdministrator",
  centralAdministrator = "centralAdministrator",
}

export enum ImputationTypeEnum {
  F = "order",
  Z = "project",
  K = "costCenter",
}

export enum BopStatus {
  Initial = "Initial",
  Simulation = "Simulation",
  Completed = "Completed",
  WaitingForValidation = "WaitingForValidation",
  Declined = "Declined",
  AcceptLunchWork = "AcceptLunchWork",
  Finished = "Finished",
  OkForReception = "OkForReception",
  Archived = "Archived",
}
export enum ComplementsCoutsSupp {
  fournitures = "fournitures",
  locations = "locations",
  sousTraitance = "sousTraitance",
  etudes = "etudes"
}

export enum VisaEventEnum {
  Signature = "Signature",
  ValidationRequest = "ValidationRequest"
}