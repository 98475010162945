































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LanguageService from "@/services/LanguageService";
import UserService from "@/services/UserService";
import User from "@/models/User";
import userRole from "@/models/UserRole";
import { loadLanguageAsync } from "@/i18n";
import store from "@/store";
import UserRoleService from "@/services/UserRoleService";
import { UserRoleEnum } from "@/utils/Enums";
@Component({
  components: {},
})
export default class AppHeader extends Vue {
  private languages: Array<any> = [];
  private userShort: string = "";

  get isActive(): Boolean {
    return !(this.$route.name == "Auth" || this.$route.name == "ResetPassword");
  }

  get isAdmin(): Boolean {
    return this.$store.getters.isAllowed([UserRoleEnum.countryAdministrator, UserRoleEnum.centralAdministrator, UserRoleEnum.siteAdministrator])
  }

  public created() {
    LanguageService.getLanguages().then((res) => {
      this.languages = res.data;
    });
  }

  public mounted() {
  }

  private logout() {
    this.$store.commit('logout');
    this.$router.push({
      path: '/Auth',
    });
  }

  private changeLanguage(language: any) {
    // this.$i18n.locale = language.code;
    loadLanguageAsync(language.code);
  }

  @Watch("isActive")
  onIsActiveChange() {
    if (this.isActive) {
    }
  }
  @Watch('$route')
  onRouteChange() {
    if (<any>this.$refs.mainDropDownMenu !== undefined)
      (<any>this.$refs.mainDropDownMenu).hide();
  }
}
