import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'Coefficients';
import  i18n  from '@/i18n';

class CoefficientService {
    
    // public async getCoefficientTypes(bopModelId:string,isLinkedToOperationPrincipal:boolean,isCoefficient:boolean,isLinkedToOperationDiverse:boolean,lang:string): Promise<any> {
    //     return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang , bopModelId,isLinkedToOperationPrincipal,isCoefficient,isLinkedToOperationDiverse})}`);
    // }
    public async getConfigurableCoefficientTypes(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/configurableCoefficients${toUrl({lang , bopModelId})}`);
    }
    public async getOperationPrincipaleCoefficients(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/operationPrincipaleCoefficients${toUrl({lang , bopModelId})}`);
    }
    
    public async getCoefficientByKeyAndBopModel(bopModelId:string,coefficientKey:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/byKeyandBopModel${toUrl({coefficientKey , bopModelId,lang})}`);
    }

    // public async getOperationDiverseCoefficients(bopModelId:string,lang:string): Promise<any> {
    //     return this.getCoefficientTypes(bopModelId,false,false,true,lang);
    // }
    public async getBopModelCoefficientTypes(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang , bopModelId})}`);
    }
    public async getCoefficientByTypeAndBopModel(bopModelId:string,coefficientType:number,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang , bopModelId,coefficientType})}`);
    }
    public async getExceptionCoefficientTypes(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/exceptionCoefficientTypes${toUrl({lang , bopModelId})}`);
    }
    
    public async getExceptionCoefficientConfigTypes(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/exceptionCoefficientConfigTypes${toUrl({lang , bopModelId})}`);
    }

    public async getCoefficientById(coefficientId:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/${coefficientId}`);
    }
    
}

export default new CoefficientService();