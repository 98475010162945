import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'UserRoles';


class UserRoleService {
  
    public async getRoles(lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang})}`);
    }
    public async getRolesByProfil(lang:string,profilName:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang,profilName})}`);
    }

    public getTranslatedRole(id:string, lang:string): any {
        return apiProvider.get(`${rootPath}/${id}${toUrl({lang: lang})}`);
    }
    
}

export default new UserRoleService();