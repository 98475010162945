import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers";
const rootPath = 'languages';
import  i18n  from '@/i18n';

class LanguageService {
  
    public async getLanguages(): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}`);
    }
    
}

export default new LanguageService();