import { Guid } from "guid-typescript";

export default class Country {
    public id: string;
    public name: string;
  
    constructor() {
        this.id = Guid.create().toString();
        this.name = '';
    }
  }
  