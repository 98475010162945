import { Guid } from "guid-typescript";

export default class DomainApplication {
    public id: string;
    public name: string;
  
    constructor() {
        this.id = Guid.parse('cfc4cba0-71bb-445c-8078-d3ded9b489c3').toString();
        this.name = '';
    }
  }
  