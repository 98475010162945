import DomainApplication from "./DomainApplication";
import { Guid } from "guid-typescript";
import Site from "@/models/Site";

export default class Entreprise {
    public id: string;
    public idSap: string;
    public name: string;
    public isEnabled: boolean;
    public domainApplication: DomainApplication;
    public sites: Array<Site>;
    public bops: Array<any>;
  
    constructor() {
        this.id = Guid.create().toString();
        this.idSap = '';
        this.name = '';
        this.isEnabled = false;
        this.domainApplication = new DomainApplication();
        this.sites = [];
        this.bops = [];
    }
  }
  