
































import { Component, Vue, Watch } from "vue-property-decorator";
import Entreprise from "@/models/Entreprise";
import EntreprisesServices from "@/services/EntreprisesServices";
import { Guid } from "guid-typescript";
import Constants from "@/utils/Constants";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    SitesTableModal: () =>
      import("@/components/Shared/Table/SitesTableModal.vue"),
    BopTableModal: () => import("@/components/Shared/Table/BopTableModal.vue"),
  },
})
export default class Entreprises extends Vue {
  // Keep track of modified Objects
  private modifiedEntreprises: Array<Object> = [];
  // API's Url
  private url: string = EntreprisesServices.getTableUrlByAdministrator(
    this.$i18n.locale
  );

  // Props for Modal on categoy field
  // Sites Modal
  private showSitesModal: Boolean = false;
  private propsSitesModal: any = false;
  // Bops Modal
  private showBopModal: Boolean = false;
  private propsBopModal: any = false;

  public data: Data = {
    columns: [
      {
        name: "editable",
        sortField: "translation.name",
        title: this.$t("entreprises.name"),
        property: "translation.name",
      },
      {
        name: "editable",
        sortField: "entreprise.idSap",
        title: this.$t("entreprises.idsap"),
        property: "entreprise.idSap",
        regex: /^[\w\W]{1,10}$/,
      },
      {
        name: "category",
        sortField: "entreprise.sites",
        title: this.$t("entreprises.sitesTitle"),
        property: "entreprise.sites",
        display: "translation.name",
      },
      {
        name: "category",
        sortField: "entreprise.bopModels",
        title: this.$t("entreprises.bopsTitle"),
        property: "entreprise.bopModels",
        display: "translation.name",
      },
      {
        name: "switch",
        sortField: "entreprise.isEnabled",
        title: this.$t("entreprises.actifs"),
        property: "entreprise.isEnabled",
      },
    ],
    rows: [],
  };

  // Validate button on modals
  public validate(entreprise: Entreprise) {
    (<any>this.$refs.table).modify(entreprise);
    this.showSitesModal = false;
    this.showBopModal = false;
  }

  // Open modal based on property
  public openModal(props: any) {
    if (props.rowField.property == "entreprise.sites") {
      this.showSitesModal = true;
      this.propsSitesModal = props;
      //console.log(props)
    } else if (props.rowField.property == "entreprise.bopModels") {
      this.showBopModal = true;
      this.propsBopModal = props;
    }
  }

  // Refresh Table
  public refresh(): void {
    this.modifiedEntreprises = [];
    (<any>this.$refs.table).refreshAPI();
  }

  // Add new Object in the table and push into modified objects
  public add(): void {
    const newEntreprise = {
      entreprise: new Entreprise(),
      translation: {
        id: Guid.create().toString(),
        language: {
          code: "fr",
        },
        name: "",
      },
    };
    (<any>this.$refs.table).updatedRows.unshift(newEntreprise);
    (<any>this.$refs.table).modify(newEntreprise);
  }

  // Save Modifications based on modified objects
  public async save() {
    let canRefresh = true;
    for (let i = 0; i < this.modifiedEntreprises.length; i++) {
      try {
        const modifiedEntreprise = this.modifiedEntreprises[i];
        //console.log(modifiedEntreprise)
        if ((<any>modifiedEntreprise).translation.name == "") {
          this.sendErrorNotification(
            this.$t("entreprise.updateFailed").toString(),
            this.$t("entreprise.propertyNameEmpty").toString()
          );
          canRefresh = false;
          break;
        } else if ((<any>modifiedEntreprise).entreprise.idSap == "") {
          this.sendErrorNotification(
            this.$t("entreprise.updateFailed").toString(),
            this.$t("entreprise.propertyIdSapEmpty").toString()
          );
          canRefresh = false;
          break;
        }
        await EntreprisesServices.addEntreprise(
          modifiedEntreprise,
          this.$i18n.locale
        );
      } catch (err) {
        let errorText: string = this.$t("entreprise.uknwownError").toString();
        if (err.response.status === 400) {
          if (err.response.data.errorCode == "idSapNotValid") {
            errorText = this.$t("entreprise.idSapNotValid").toString();
          } else if (err.response.data.errorCode === "duplicateIdSap") {
            errorText = this.$t("entreprise.duplicateIdSap").toString();
          } else {
            errorText = this.$t("entreprise.incorrectValues").toString();
          }
        }
        this.sendErrorNotification(
          this.$t("entreprise.updateFailed").toString(),
          errorText
        );

        canRefresh = false;
      }
    }
    if (canRefresh) {
      this.refresh();
    }
  }

  private sendErrorNotification(title: string, text: string) {
    this.$notify({
      group: "globalError",
      type: "error",
      title,
      text,
      duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
    });
  }
  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.url = EntreprisesServices.getTableUrlByAdministrator(
        this.$i18n.locale
    );
    this.$forceUpdate();
  }
}
