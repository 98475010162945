






































































import { Component, Vue } from "vue-property-decorator";
import apiProvider from "@/utils/api";
import UserService from "@/services/UserService";
import router from "@/router/index";
import store from "@/store";
import Constants from "@/utils/Constants";
type User = {
  username: string;
  password: string;
  remember: Boolean;
};

@Component({
  components: {
    LoginModal: () => import("@/components/Auth/LoginModal.vue"),
  },
})
export default class Login extends Vue {
  private showModal: boolean = false;
  public submitted: boolean = false;
  public loading: boolean = false;
  public isSso: boolean = false;
  private get version (){
    return this.$store.state.APP_VERSION;
  }
    

    private created() {
        if (this.$route.query.noty == "error") {
            this.sendNotificationError(this.$t("auth.errorInvalidCombination").toString());
        }
    }

  // get return url from route parameters or default to '/'
  public get returnUrl(): string {
    return this.$route.query.returnUrl?.toString() || "/";
  }
  public error: string = "";
  private user: User = {
    username: "",
    password: "",
    remember: false,
  };
  private regexPassword: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/;

  private async login(useSso: boolean = false) {
    this.$store.commit('changeHasBeenModified',false);
    if (useSso) {
      const resolved = this.$router.resolve({
        name: "SsoCallBack",
      });
      document.location.href = `${apiProvider.getUrl()}auth/authenticate?returnUrl=${
        document.location.origin
      }${resolved.href}?returnUrl=${ this.returnUrl }`;
      return;
    }
    if (this.user.password == "")
      this.sendNotificationError(this.$t("auth.errorEmptyPassword").toString());
    //else if(!this.regexPassword.test(this.user.password)) this.sendNotificationError('Your password must have at least 8 characters !');
    if (this.user.username == "")
      this.sendNotificationError(this.$t("auth.errorEmptyLogin").toString());
    this.submitted = true;
    // stop here if form is invalid
    if (!(this.user.username && this.user.password)) {
      return;
    }

    this.loading = true;

    this.$store
      .dispatch("login", {
        Login: this.user.username,
        Password: this.user.password,
        UseSso: useSso,
      })
      .then(
        (user) => {
          if(this.returnUrl.toLowerCase() !== '/bops/add' && this.returnUrl.toLowerCase().includes('/bops/')){
            this.$router.push({ path: this.returnUrl })
          }else {
            router.go(0);
          }

          // this.sendNotificationSuccess(
          //   this.$t("auth.welcome").toString() + " " + user.firstName
          // );
          //location.href = this.returnUrl;
          // this.$router.push({ path: this.returnUrl })
        },
        (error) => {
          this.error = error;
          if (error.response.status === 403) {
            this.sendNotificationError(
              this.$t("auth.errorAccountDisabled").toString()
            );
          } else {
            this.sendNotificationError(
              this.$t("auth.errorInvalidCombination").toString()
            );
          }
          this.loading = false;
        }
      );
  }

  private sendNotificationError(text: any): void {
    this.$notify({
      group: "globalError",
      type: "error",
      title: this.$t("auth.errorLogin").toString(),
      text: text,
      duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
    });
  }

  private sendNotificationSuccess(text: any): void {
    this.$notify({
      group: "global",
      type: "success",
      title: this.$t("auth.successfullLogin").toString(),
      text: text,
    });
  }
}
