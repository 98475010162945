




import { Component, Vue, Watch } from "vue-property-decorator";
import UserService from '@/services/UserService';
    import router from '@/router/index';
import { RawLocation } from "vue-router";


@Component({ 
})
export default class SsoCallBack extends Vue {
   
    private created() {
        var bearer = this.$route.query.jwt;
        var returnUrl = this.$route.query.returnUrl as RawLocation;
        if (bearer) {
            UserService.getConnectedUser(bearer).then(user => {
                this.$store.dispatch('loginSso', { user: user.data, bearer: bearer });
                router.push(returnUrl || '/accueil');
            }).catch(error => {
                //User could not be found.. redirect to /auth page with an error notif
                router.push('/Auth?noty=error');
            })

        }
        else {
            //Something went wrong with authentication.. redirect to /auth page with an error notif
            router.push('/Auth?noty=error');
        }

  }

}
