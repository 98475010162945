import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"

const rootPath = 'Entreprises';
import  i18n  from '@/i18n';


class SitesServices {
    public getTableUrl(lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
    }

    public getByIdAndLanguage(id: string, lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/GetByIdAndLanguage/${id}${toUrl({lang: lang})}`);
    }

    public getTableUrlByAdministrator(lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}/GetByAdministrator${toUrl({lang: lang})}`;
    }

    public async getEntreprises(lang:string): Promise<any> {
        return apiProvider.get(this.getTableUrl(lang));
    }

    public async getEntreprisesBySite(lang:string,siteId:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/bysite${toUrl({lang,siteId})}`);
    }

    public addEntreprise(data: any,lang:string): AxiosPromise {
        return apiProvider.put(`${rootPath}/${data.entreprise.id}${toUrl({lang: lang})}`, data);
    }
}

export default new SitesServices();