import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
import Services from '@/models/Services';
const rootPath = 'SapApi';

import  i18n  from '@/i18n';

class SapApiService {
    public getServicesUrl(poNumber:string, itemNumber:string, lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}/services/${poNumber}/${itemNumber}/${lang}`;
    }
    public getServices(poNumber:string, itemNumber:string, lang:string): AxiosPromise{
        return apiProvider.get(this.getServicesUrl(poNumber,itemNumber,lang))
    }

    public getSendBopUrl(bopId:string): string {
        return `${apiProvider.getUrl()}${rootPath}/send/${bopId}`;
    }

    public getValidateBopUrl(bopId:string): string {
        return `${apiProvider.getUrl()}${rootPath}/validate/${bopId}`;
    }

    public sendBop(bopId:string,validation:any){
       return apiProvider.post(this.getSendBopUrl(bopId),validation);
    }

    public validateBop(bopId:string,validation:any){
        return apiProvider.post(this.getValidateBopUrl(bopId),validation);
     }
}

export default new SapApiService();