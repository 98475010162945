import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import { toUrl } from "@/utils/helpers";
const rootPath = "Import";

class ImportService {
  public async importTranslations(file) {
    return await apiProvider.post(`${rootPath}/ImportTranslationsFile`, file, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
  public async importBopModel(file) {
    return await apiProvider.post(`${rootPath}/ImportBopModel`, file, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  public async ImportUsers(file : FormData) {
    return await apiProvider.post(`${rootPath}/ImportUsersFile`, file, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
}

export default new ImportService();
