import Country from "./Country";
import { Guid } from "guid-typescript";

export default class Site {
    public id: string;
    public idSap: string;
    public isEnabled: boolean;
    public country: Country;

    constructor() {
        this.id = Guid.create().toString();
        this.idSap = "";
        this.isEnabled = true;
        this.country = new Country();
        this.country.id = "";
    }
}
