


















import { Component, Vue, Watch } from "vue-property-decorator";
import Site from "@/models/Site";
import SitesServices from "@/services/SitesServices";
import CountryService from "@/services/CountryService";
import { Guid } from "guid-typescript";
import { UserRoleEnum } from "@/utils/Enums";
import ExceptionSetup from "@/components/BopModelSetup/ExceptionSetup.vue";
import Constants from "@/utils/Constants";

type Data = {
  columns: Array<Object>;
  rows: Array<Site>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
  },
})
export default class Sites extends Vue {
  private showModal: boolean = false;
  private modifiedSites: Array<Object> = [];
  private url: string = SitesServices.getTableUrlByAdministrator(this.$i18n.locale);
  private countries: Array<any> = [];
  public data: Data = {
    columns: [],
    rows: [],
  };
  private user: any = {};

  public refresh(): void {
    this.modifiedSites = [];
    (<any>this.$refs.table).refreshAPI();
  }

  public close(): void {
    this.showModal = false;
    this.refresh();
  }

  public add(): void {
    const newSite = {
      site: new Site(),
      translation: {
        id: Guid.create().toString(),
        language: {
          code: "fr",
        },
        name: "",
      },
    };
    (<any>this.$refs.table).updatedRows.unshift(newSite);
    (<any>this.$refs.table).modify(newSite);
  }

  public async save() {
    for (let modifiedSite of this.modifiedSites) {
      if ((<any>modifiedSite).translation.name == "") {
        this.sendNotification(
          this.$t("sites.updateFailed").toString(),
          this.$t("sites.propertyNameEmpty").toString(),
          "error"
        );
        return;
      } else if ((<any>modifiedSite).site.country.id == "") {
        this.sendNotification(
          this.$t("sites.updateFailed").toString(),
          this.$t("sites.propertyCountryEmpty").toString(),
          "error"
        );
        return;
      }
    }
    try {
      const response = await SitesServices.saveSites(
        this.modifiedSites,
        this.$i18n.locale
      );
      if (response.status === 200) {
        this.refresh();
        this.sendNotification(
          this.$t("sites.updateSuccess.title").toString(),
          this.$t("sites.updateSuccess.message").toString(),
          "success"
        );
      }
    } catch (err) {
      let errorText: string = this.$t("sites.uknownError").toString();
      if (err.response.status === 400) {
        if (err.response.data.errorCode == "idSapNotValid") {
          errorText = this.$t("sites.idSapNotValid").toString();
        } else if (err.response.data.errorCode === "duplicateIdSap") {
          errorText = this.$t("sites.duplicateIdSap").toString();
        } else if (err.response.data.errorCode === "notEnoughPermission") {
          errorText = this.$t("sites.notEnoughPermission").toString();
        }else {
          errorText = this.$t("sites.incorrectValues").toString();
        }
      }
      this.sendNotification(
        this.$t("sites.updateFailed").toString(),
        errorText,
        "error"
      );
    }

  }

  private sendNotification(title: string, text: string, type: string) {
    const isError = (type === "error");
      let notifObject: any = {
      group: isError ? "globalError": "global",
      type,
      title,
      text,
    };
    if (isError) {
      notifObject.duration = Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS;
    }
    this.$notify(notifObject);
  }
  private loadElements() {
    this.data.columns = [
      {
        name: "editable",
        sortField: "translation.name",
        title: this.$t("sites.name").toString(),
        property: "translation.name",
      },
      {
        name: "dropdown",
        sortField: "site.country.name",
        title: this.$t("sites.country").toString(),
        property: "site.country.id",
        options: this.countries,
        valueField: "id",
        textField: "name",
        display: "name",
      },
      {
        name: "editable",
        sortField: "site.idSap",
        title: this.$t("sites.idSap").toString(),
        property: "site.idSap",
        regex: /^\w{4}$/,
      },
      {
        name: "switch",
        sortField: "site.isEnabled",
        title: this.$t("sites.actifs").toString(),
        property: "site.isEnabled",
      },
    ];
  }

  public async mounted() {
    this.countries = (await CountryService.getCountriesByAdministrator()).data;
    this.loadElements();

    this.user = JSON.parse(<string>localStorage.getItem("user"));
    // console.log(this.$store);
  }

  get isAllowedToCreate() {
    return !this.$store.getters.isAllowed([UserRoleEnum.countryAdministrator, UserRoleEnum.centralAdministrator]);
    //return !(this.user && this.user.role && this.user.role.key ? this.user.role.key == UserRoleEnum.countryAdministrator || this.user.role.key == UserRoleEnum.centralAdministrator : false);
  }

  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.url = SitesServices.getTableUrlByAdministrator(this.$i18n.locale);
    this.loadElements();
  }
}
