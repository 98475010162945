import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers";
const rootPath = 'countries';
import  i18n  from '@/i18n';

class CountryService {
  
    public async getCountries(): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}`);
    }

    public async getCountriesByAdministrator(): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/getByAdministrator`);
    }

    public async getCountryBySite(siteId:string) {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/bySite${toUrl({siteId})}`);
    }
    
}

export default new CountryService();