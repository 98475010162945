import  User  from '@/models/User';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import moment from 'moment';
import i18n from './i18n'
import apiProvider from "@/utils/api";
import axios, { AxiosInstance } from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import authHeader from '@/utils/auth-header';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import './styles/app.scss'
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.filter('formatDate', function (value: Date) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
})

// Register Global components

// vue-editable-grid component and styles
const VueEditableGrid = require('vue-editable-grid');
import 'vue-editable-grid/dist/VueEditableGrid.css';
Vue.component('vue-editable-grid', VueEditableGrid);

//vuetable-2
import Vuetable from 'vuetable-2';
Vue.component("vuetable", Vuetable);

// notifications
import Notifications from 'vue-notification';
import velocity      from 'velocity-animate';
Vue.use(Notifications, { velocity });


declare module 'vue/types/vue' {
    interface Vue {
        $api: AxiosInstance
    }
}

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

let vueInstance;
//Read the config.json file and instantiate the api AxiosInstance
fetch(process.env.BASE_URL + "config.json").then(req => {
    req.json().then(conf => {
        Vue.prototype.$api = axios.create({
            baseURL: conf.API_URL
        });
        Vue.prototype.$api.interceptors.request.use(function (config) {
            const user:User = store.getters.user;
            if (user && user.authenticationToken) {
                config.headers['Content-Type'] = 'application/json';
                config.headers['Authorization'] = 'Basic ' + user.authenticationToken;
            }
            else if (user && user.bearerToken) {
                //Check token validity
                const jwtPayload = parseJwt(user.bearerToken);
                if (jwtPayload.exp < Date.now() / 1000) {
                    store.commit('logout');
                    const resolved = router.resolve({
                        name: 'SsoCallBack',
                    });
                    document.location.href = `${apiProvider.getUrl()}auth/authenticate?returnUrl=${document.location.origin}${resolved.href}`;
                }
                config.headers['Content-Type'] = 'application/json';
                config.headers['Authorization'] = 'Bearer ' + user.bearerToken;
            }
            else {
                config.headers['Access-Control-Allow-Origin'] = '*';
                config.headers['Allow'] = '*';
            }
            return config;
        });
        vueInstance = new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app');
    })
});
export  function  getVueInstance(){
    return vueInstance;
}
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};