import { Mutation, State, Getter } from 'vuex-simple';
import UserService from '@/services/UserService';
import UserRoleService from '@/services/UserRoleService';
import { AxiosResponse } from 'axios';
import User from '@/models/User';
import i18n from "@/i18n";



export default {
    state: () => ({ 
        user: {}
    }),
    mutations: { 
        login(state: any, user: any) {
            state.user = user;
        },
        logout(state: any) {
            state.user = {};
        },
        updateTranslation(state: any, lang: any) {
            if(!state.user.role)return
            UserRoleService.getTranslatedRole(state.user.role.id || state.user.role.role.id, lang).then(
                (role: { data: { translation: { name: string } } }) => {
                    state.user.role = role.data;
                }
            );
        }
    },
    actions: { 
        login(context: any, data: any) {
            context.commit('logout');

            return UserService.loginStore(data)
                .then((response: AxiosResponse<User>) => {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        context.commit('logout');
                        const error = response.statusText;
                        return Promise.reject(error);
                    }
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .then((user: any)=> {
                    // login successful if there's a user in the response
                    if (user) {
                        // store user details and basic auth credentials in local storage 
                        // to keep user logged in between page refreshes
                        // user.authdata = window.btoa(data.Login + ':' + data.Password);
                        context.commit('login', user);
                    }
        
                    return user;
                });
        },
        async refreshUser(context: any) {
            try{
            const res = (await UserService.refreshCurrentUser());
            if(res.status == 200) {
                // const authdata = context.getters.user.authdata;
                let user = res.data;
                    // user.authdata = authdata;
                context.commit('login', user);
                return true;
            }
            }catch{
            }
            return false;
          
        },
        loginSso(context: any, data: any) {
            context.commit('logout');
            data.user.bearerToken = data.bearer;
            context.commit('login', data.user);
        }
    },
    getters: { 
        isLoggedIn(state: any) {
            return Object.keys(state.user).length > 0;
        },
        user(state: any) {
            return state.user;
        },
        userShort(state: any, getters: any) {
            return !getters.isLoggedIn 
                ? '' 
                : `${state.user.firstName.charAt(0)}.${state.user.lastName}`;
        },
        userRole(state: any) {
            if(state.user.role) {
                if(state.user.role.translation) {
                    return state.user.role.translation
                        ? state.user.role.translation.name
                        : i18n.t("menu.roleGuest").toString();
                } else {
                    UserRoleService.getTranslatedRole(state.user.role.id || state.user.role.id, i18n.locale).then(
                        (role: { data: { translation: { name: string } } }) => {
                            state.user.role = role.data;
                        }
                    );
                }
            }
            return '';
        },
        userRoleKey(state: any) {
            return state.user.role.key || state.user.role.role.key;
        },
        isAllowed: (state: any, getters: any) => (authorisations: any) => {
            if(getters.isLoggedIn) {
                if(Array.isArray(authorisations)) {
                    return authorisations.includes(getters.userRoleKey);
                } else {
                    return authorisations == getters.userRoleKey;
                }
            } else {
                return false;
            }            
        }
    }
};