import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'bopmodels';

class BopModelService {
    public getTableUrl(lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
    }
    public async getBopModels(lang:string): Promise<any> {
        return apiProvider.get(this.getTableUrl(lang));
    }
    public async getBopModel(bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/${bopModelId}${toUrl({lang})}`);
    }
    public async getDeployedBopModelsOnSite(siteId:string,entrepriseId:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/deployed${toUrl({siteId,entrepriseId})}`);
    }
    public async CanHaveExceptions(id:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/${id}/canHaveExceptions`);
    }
    public getTranslationsUrl(params: any): string {
        return `${apiProvider.getUrl()}${rootPath}/GetAllTranslations${toUrl({bopModelKey: params.bopModelKey})}`;
    }
    public async listByEntrepriseAndTypeTravaux(entrepriseId:any,typetravauxId:any,lang:string): Promise<any> {
        return apiProvider.get(`${rootPath}/entreprise/${entrepriseId}/typetravaux/${typetravauxId}${toUrl({lang: lang})}`);
    }

    public save(data: any,lang:string): AxiosPromise {
        return apiProvider.put(`${rootPath}/${data.site.id}${toUrl({lang: lang})}`, data);
    }

    public addTranslations(data: any,lang:string): AxiosPromise {
        return apiProvider.put(`${rootPath}/addTranslations/${data.id}`, data);
    }

    public updateNonNullProperties(data: any,lang:string): AxiosPromise {
        return apiProvider.patch(`${rootPath}/${data.id}${toUrl({lang: lang})}`, data);
    }
}

export default new BopModelService();