import ExportService, {ExportDto} from "@/services/ExportService";

export default class ExportHelper{

    public static _base64ToArrayBuffer(base64) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public static async handleExport(selectedBopModelKey,languageCode:string ) {
        if(!selectedBopModelKey)return ;
        const exportDto : ExportDto = (await ExportService.exportBopModel(selectedBopModelKey, languageCode)).data;
        ExportHelper.exportData(exportDto);
    }

    public static exportData(exportBopModelDto:ExportDto ) {
        const data = this._base64ToArrayBuffer(exportBopModelDto.generatedExcelData);
        const blob = new Blob([data], {type: 'application/octet-stream'});

        ExportHelper.downloadBlob(blob,`${exportBopModelDto.name}`);
    }
    public static downloadBlob(blob, name) {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;
        // link.style.display = "none";
        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }
}