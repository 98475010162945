import defaultLanguage from '@/i18n';
import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import { toUrl } from "@/utils/helpers"
const rootPath = 'interfaceElements';
import { Vue } from 'vue-property-decorator';

class InterfaceElementService extends Vue {

    public async GetTranslationFile(lang: string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/GetTranslationFile${toUrl({ lang })}`);
    }

    public getTranslationsUrl(): string {
        return `${apiProvider.getUrl()}${rootPath}/GetAllTranslations`;
    }

    public addTranslations(data: any): AxiosPromise {
        return apiProvider.put(`${rootPath}/addTranslations/${data.id}`, data);
    }

}

export default new InterfaceElementService();