
export default class Validation {
    public sapPrestationServiceNumber:string;
    public sapSupplementServiceNumber:string;
    public sapEPIServiceNumber:string;
    public WBSelement:string;
    public order:string;
    public purchaseOrder:string;
    public itemNumber:string;
    public imputationType:string;
    public costCenter:string
    public materialCost:number;
    public rentingCost:number;
    public subcontractingCost:number;
    public studyCost:number;
    public totalPrincipalDiversPoint:number;
    public totalOperationDiversePoints:number;
    public totalAddPoints:number;
    public totalEpiPoints:number;
    public totalPoints:number;
    public isEdit:boolean;
    public language:string;

    public daId:string;
    public itemDa:string;
    public bopStatus:string;
    public bopLink:string;

    constructor(){
        this.itemNumber='';
        this.WBSelement = '';
        this.costCenter='';
        this.order=''
        this.imputationType='';
        this.materialCost=0;
        this.purchaseOrder='';
        this.rentingCost=0;
        this.sapEPIServiceNumber='';
        this.sapPrestationServiceNumber='';
        this.sapSupplementServiceNumber='';
        this.studyCost=0;
        this.subcontractingCost=0;
        this.totalPrincipalDiversPoint=0;
        this.totalOperationDiversePoints=0;
        this.totalAddPoints=0;
        this.totalEpiPoints=0;
        this.totalPoints=0;
        this.isEdit=false;
        this.language=''

        this.daId='';
        this.itemDa = '';
        this.bopStatus='';
        this.bopLink='';
    }
}