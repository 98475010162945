import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'Sites';


class SitesServices {
    public getTableUrl(lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
    }

    public getTableUrlByAdministrator(lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}/getByAdministrator${toUrl({lang: lang})}`;
    }

    public async getSites(lang:string): Promise<any> {
        return apiProvider.get(this.getTableUrl(lang));
    }
    public async getSitesByAdministrator(lang:string): Promise<any> {
        return apiProvider.get(this.getTableUrlByAdministrator(lang));
    }
    public async getEnabledSites(lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/enabled${toUrl({lang: lang})}`);
    }
    public async getEntrepriseSites(entrepriseId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/entreprise${toUrl({lang,entrepriseId})}`);
    }

   

    public getTranslatedSite(id: any,lang:string): Promise<any> {
        return apiProvider.get(`${rootPath}/${id}${toUrl({lang: lang})}`);
    }
    public addSite(data: any,lang:string): AxiosPromise {
        return apiProvider.put(`${rootPath}/${data.site.id}${toUrl({lang: lang})}`, data);
    }
    public saveSites(data: Array<any>,lang:string): AxiosPromise {
        return apiProvider.post(`${rootPath}${toUrl({lang: lang})}`, data);
    }
    public DeployBopModels(siteId:string,bopModelGuids: Array<string>){
        return apiProvider.post(`${rootPath}/${siteId}/deploybopmodels`, bopModelGuids);
    }
    public getTranslationsUrl(): string {
        return `${apiProvider.getUrl()}${rootPath}/GetAllTranslations`;
    }
    public saveTranslations(data: any): AxiosPromise {
        return apiProvider.post(`${rootPath}/translations`, data);
    }

}

export default new SitesServices();