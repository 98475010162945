


















































































import { Component, Vue, Watch } from "vue-property-decorator";
import LanguageService from "@/services/LanguageService";
import InterfaceElementService from "@/services/InterfaceElementService";
import BopModelService from "@/services/BopModelService";
import TranslationService from "@/services/TranslationService";
import OperationTypeService from "@/services/OperationTypeService";
import { RubriqueEnum } from "@/utils/Enums";

import { loadLanguageAsync } from "@/i18n";
import SitesServices from "@/services/SitesServices";
import Constants from "@/utils/Constants";

import ImportLoadingModal from "@/components/Translations/ImportLoadingModal.vue"
import ExportService from "@/services/ExportService";

type Data = {
  columns: Array<Object>;
};
enum ElementTypes {
  bordereaux = "bordereaux",
  interface = "interface",
  sites = "sites",
}
@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    ImportLoadingModal: ImportLoadingModal
  },
})
export default class Translations extends Vue {
  private showModal: boolean = false;
  private modifiedTranslations: Array<Object> = [];
  private elementTypes: Array<any> = [
    {
      text: this.$t("translations.bordereaux").toString(),
      value: ElementTypes.bordereaux,
    },
    {
      text: this.$t("translations.interface").toString(),
      value: ElementTypes.interface,
    },
    {
      text: this.$t("translations.sites").toString(),
      value: ElementTypes.sites,
    },
  ];
  private selectedElementType: any = ElementTypes.interface;
  private bopModels: Array<Object> = [];
  private selectedBopModel: any = null;
  private references: Array<Object> = [];
  private selectedReference: any = null;
  private url: string = InterfaceElementService.getTranslationsUrl();
  private exportUrl: string = ExportService.getUrl();

  public data: Data = {
    columns: [],
  };
  private get canShowExportLoader():boolean{
    return this.$store.state.showExportLoader;
  }
  private handleImport() {
    (<ImportLoadingModal>this.$refs.importModal).openImportModal();
  }

  public handleImportSuccess() {
    this.handleChange();
    this.refresh();
  }

  public handleSearch(value: any): void {
    this.references;
  }

  public handleChange(): void {
    this.modifiedTranslations = [];
    (<any>this.$refs.table).hasBeenModified = false;
    switch (this.selectedElementType) {
      case ElementTypes.bordereaux:
        let queryParams = {
          bopModelKey: "",
          referenceKey: "",
        };
        if (this.selectedBopModel != null) {
          queryParams.bopModelKey = this.selectedBopModel;
        }
        if (this.selectedReference != null) {
          queryParams.referenceKey = this.selectedReference;
        }
        this.url = TranslationService.getTranslationsUrl(queryParams);
        break;
      case ElementTypes.interface:
        this.url = InterfaceElementService.getTranslationsUrl();
        break;
      case ElementTypes.sites:
        this.url = SitesServices.getTranslationsUrl();
        break;
    }

    this.$forceUpdate();
  }

  public refresh(): void {
    this.modifiedTranslations = [];
    (<any>this.$refs.table).refreshAPI();
  }

  public async save() {
    for (let i = 0; i < this.modifiedTranslations.length; i++) {
      const modifiedTranslation = this.modifiedTranslations[i];
      try {
        switch (this.selectedElementType) {
          case ElementTypes.bordereaux:
            await TranslationService.save(modifiedTranslation);
            break;
          case ElementTypes.interface:
            await InterfaceElementService.addTranslations(modifiedTranslation);
            break;
          case ElementTypes.sites:
            await SitesServices.saveTranslations(modifiedTranslation);
            break;
        }
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t("translations.saveSuccess.title").toString(),
          text: this.$t("translations.saveSuccess.text").toString(),
        });
      } catch (err) {
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t("translations.saveError.title").toString(),
          text: this.$t("translations.saveError.text").toString(),
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
    await loadLanguageAsync(this.$i18n.locale, true);
    this.handleChange();
    this.refresh();
  }
  private async loadElements() {
    this.data.columns = [];
    LanguageService.getLanguages().then((res) => {
      this.data.columns.push({
        name: "editable",
        sortField: "name",
        title: this.$t("translations.name"),
        property: "name",
        isNotEditable: true,
      });
      res.data.forEach((element: any) => {
        this.data.columns.push({
          name: "editable",
          sortField: "translations." + element.code,
          title: element.code,
          property: "translations." + element.code,
        });
      });
    });

    let responseBopModel = await BopModelService.getBopModels(
      this.$i18n.locale
    );
    this.bopModels.push({
      translation: { name: this.$t("bopModelSetup.allBops") },
      bopModel: { key: null },
    });
    if (responseBopModel.status === 200) {
      this.bopModels = responseBopModel.data;
    }

    this.references.push(
      ...[
        {
          text: this.$t("bopModelSetup.allReferences"),
          value: null,
        },
        {
          text: this.$t("bopModelSetup.OperationPrincipale"),
          value: RubriqueEnum.OperationPrincipal,
        },
        {
          text: this.$t("bopModelSetup.OperationDiverse"),
          value: RubriqueEnum.OperationDiverse,
        },
        {
          text: this.$t("bopModelSetup.CoefficientDimensions"),
          value: RubriqueEnum.CoefficientDimensions,
        },
      ]
    );
  }
  public mounted() {
    this.loadElements();
  }
  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.loadElements();
  }
}
