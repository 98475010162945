import Country from "./Country";
import { Guid } from "guid-typescript";
import Entreprise from "./Entreprise";
import Site from "./Site";
import UserProfil from "./UserProfil";
import UserRole from "./UserRole";

export default class User {
    public id: string;
    public firstName: string;
    public lastName: string;
    public idSap: string;
    public mail: string;
    public login: string;
    public isContact: boolean;
    public isEnabled: boolean;
    public authenticationToken:string;
    public entreprise: Entreprise;
    public entrepriseId: string;
    public profil: UserProfil;
    public role: any;
    public site: Site;

    // public authdata: string;
    public bearerToken: string;
    constructor() {
        this.id = Guid.create().toString();
        this.firstName = '';
        this.lastName = '';
        this.isEnabled = false;
        this.entreprise = new Entreprise();
        this.site = new Site();
        this.idSap = '';
        this.mail = '';
        this.login = '';
        this.isContact = false;
        this.profil = new UserProfil();
        // this.authdata = '';
        this.bearerToken = '';
        this.authenticationToken = '';
        this.entrepriseId = ''

    }
}
