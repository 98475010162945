import store from "@/store";
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import InterfaceElementService from "@/services/InterfaceElementService";
Vue.use(VueI18n);

export const defaultLanguage: string = getDefaultLanguage();

const dateTimeFormats: VueI18n.DateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

function getDefaultLanguage(): string {
  let language = localStorage.getItem("language");

  if (!language || language == undefined)
    language = navigator.language.split("-")[0];
  language = !(language && language.length) ? "en" : language;

  return language;
}

const loadedLanguages: Array<string> = []; // our default language that is preloaded

function setI18nLanguage(lang: any):string {
  i18n.locale = lang;
  localStorage.setItem("language", lang);
  axios.defaults.headers.common["Accept-Language"] = lang;
  const docHtml = document.querySelector("html");
  if (docHtml != null) docHtml.setAttribute("lang", lang);

  store.commit("changeIsTranslationLoaded", true);

  return lang;
}

async function getTranslations(lang: any) {
  if (Vue.prototype.$api) {
    const response = await InterfaceElementService.GetTranslationFile(lang);
    i18n.setLocaleMessage(lang, response.data);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  } else {
    const req = await fetch(process.env.BASE_URL + "config.json");
    const conf = await req.json();
    const response = await axios.get(
      `${
        conf.API_URL
      }interfaceElements/GetTranslationFile?${new URLSearchParams({
        lang,
      }).toString()}`
    );

    i18n.setLocaleMessage(lang, response.data);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  }
}

export async function loadLanguageAsync(lang: any, force: boolean = false) {
  // If the same language

  // If the language was already loaded
  if (loadedLanguages.includes(lang) && !force) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  store.commit("changeIsTranslationLoaded", false);
  // If the language hasn't been loaded yet
  store.commit("updateTranslation", lang);
  return await getTranslations(lang);
}

const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  dateTimeFormats,
  messages: {},
  silentTranslationWarn: true,
});

export default i18n;
// setI18nLanguage(i18n.locale);
// getTranslations(i18n.locale);
