import { Guid } from "guid-typescript";
import UserRole from "./UserRole";

export default class UserProfil {
    public id: string;
    public profilKey: string;
    public roles: Array<UserRole>;

    constructor() {
        this.id = Guid.create().toString();
        this.profilKey = "";
        this.roles = [];
    }
}
