import Country from "./Country";
import { Guid } from "guid-typescript";
import Entreprise from "./Entreprise";
import Site from "./Site";
import UserProfil from "./UserProfil";
import UserRole from "./UserRole";
import Vue from 'vue';


export default class ManageUser {
    public id: string;
    public login: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public idSap?: string;
    public mail: string;
    public isContact: boolean;
    public isEnabled: boolean;

    public idEntreprise?: string;
    public nameEntreprise?: string;

    public sites: Array<Site>;
    
    public idRole?: string;
    public nameRole: string;
    public idProfil?: string;
    public nameProfil: string;
    public keyProfil?: string;


    constructor() {
        this.id = Guid.create().toString();
        this.firstName = '';
        this.lastName = '';
        this.isEnabled = false;
        this.idSap = undefined;
        this.mail = '';
        this.login = '';
        this.password = '';
        this.isContact = false;

        this.sites = [];
        this.idEntreprise = undefined;
        this.nameEntreprise = '';

        this.idProfil = undefined;
        this.nameProfil = '';
        this.keyProfil = undefined;

        this.idRole = undefined;
        this.nameRole = '';
    }
}
