import apiProvider from "@/utils/api";
import axios, {AxiosPromise, AxiosResponse} from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'Export';

export type ExportDto = {
    name:string,
    generatedExcelData: string,
}

class ExportService {
    public async get() {
        return await apiProvider.get(`${rootPath}`);
    }

    public getUrl(): string {
        return `${apiProvider.getUrl()}${rootPath}`;
    }

    public exportBopModelUrl(bopModelKey:string,language:string):string{
        return `${this.getUrl()}/bopModels/${bopModelKey}${toUrl({language})}`;
    }
    public exportBopModel(bopModelKey:string,language:string): Promise<AxiosResponse<ExportDto>>{
        return apiProvider.get(this.exportBopModelUrl(bopModelKey,language));
    }
}

export default new ExportService();