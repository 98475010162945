import apiProvider from "@/utils/api";
import {toUrl} from "@/utils/helpers"
const rootPath = 'bopStatuses';

import  i18n  from '@/i18n';
class BopStatusService {
    
    public getAllStatuses(lang:string) {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang})}`);
    }

}

export default new BopStatusService();