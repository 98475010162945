import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers";
const rootPath = 'Translations';

class TranslationService {
  
    public async getTranslations(params: any): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({bopModelKey: params.bopModelKey})}`);
    }

    public getTranslationsUrl(params: any): string {
        return `${apiProvider.getUrl()}${rootPath}${toUrl({...params})}`;
    }

    public save(data: any): AxiosPromise {
        return apiProvider.put(`${rootPath}/${data.id}`, data);
    }
    
}

export default new TranslationService();