


















import { Component, Vue, Watch } from "vue-property-decorator";
import UserService from "@/services/UserService";
import UserRoleService from "@/services/UserRoleService";
import EntreprisesServices from "@/services/EntreprisesServices";
import ManageUser from "@/models/ManageUser";
import { UserProfilEnum, UserRoleEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
  },
})
export default class ExternalUsers extends Vue {
  public data: Data = {
    columns: [],
    rows: [],
  };
  private modifiedUsers: Array<ManageUser> = [];
  private url: string = UserService.getTableUrl(
    "externalUser",
    this.$i18n.locale
  );
  private roles: Array<any> = [];
  private entreprises: Array<any> = [];
  public async save() {
    for (let i = 0; i < this.modifiedUsers.length; i++) {
      if (this.modifiedUsers[i].firstName == "") {
        this.sendErrorNotification(
          this.$t("externalUsers.updateFailed").toString(),
          this.$t("externalUsers.propertyFirstNameEmpty").toString()
        );
        return;
      } else if (this.modifiedUsers[i].lastName == "") {
        this.sendErrorNotification(
          this.$t("externalUsers.updateFailed").toString(),
          this.$t("externalUsers.propertyLastNameEmpty").toString()
        );
        return;
      } else if (this.modifiedUsers[i].login == "" ||/\s/.test(this.modifiedUsers[i].login)) {
        this.sendErrorNotification(
          this.$t("externalUsers.updateFailed").toString(),
          this.$t("externalUsers.propertyLoginValidationError").toString()
        );
        return;
      } else if (this.modifiedUsers[i].mail == "") {
        this.sendErrorNotification(
          this.$t("externalUsers.updateFailed").toString(),
          this.$t("externalUsers.propertyMailEmpty").toString()
        );
        return;
      }
    }
    try {
      await UserService.saveUsers(this.modifiedUsers, this.$i18n.locale);
      this.refresh();
      this.$store.dispatch('update');
      this.$notify({
        group: "global",
        type: "success",
        title: this.$t("externalUsers.updateSuccess.title").toString(),
        text: this.$t("externalUsers.updateSuccess.description").toString(),
      });
    } catch (err) {
      let errMessage = this.$t("externalUsers.uknownError").toString();
      if (err.response.status === 400 || err.response.status === 500) {
        switch (err.response.data.errorCode) {
          case "duplicateUserLogin":
            errMessage = this.$t("externalUsers.duplicateUserLogin").toString();
            break;
          case "duplicateUserMail":
            errMessage = this.$t("externalUsers.duplicateUserMail").toString();
            break;
          case "duplicateUserIdSap":
            errMessage = this.$t("externalUsers.duplicateUserIdSap").toString();
            break;
          case "notEnoughPermissions":
            errMessage = this.$t("externalUsers.notEnoughPermissions").toString();
            break;
          default:
            errMessage = this.$t(`externalUsers.${err.response.data.errorCode}`).toString();
        }
      }
      this.sendErrorNotification(
        this.$t("externalUsers.updateFailed").toString(),
        errMessage
      );
    }
  }
  private refresh() {
    this.modifiedUsers = [];
    (<any>this.$refs.table).refreshAPI();
  }
  private sendErrorNotification(title: string, text: string) {
    this.$notify({
      group: "globalError",
      type: "error",
      title: title,
      text: text,
      duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
    });
  }

  public add(): void {
    const newUser = new ManageUser();
    newUser.keyProfil = "externalUser";
    (<any>this.$refs.table).updatedRows.unshift(newUser);
    (<any>this.$refs.table).modify(newUser);
  }
  private async loadElements() {
    this.roles = (
      await UserRoleService.getRolesByProfil(
        this.$i18n.locale,
        UserProfilEnum.externalUser
      )
    ).data;
    this.entreprises = (
      await EntreprisesServices.getEntreprises(this.$i18n.locale)
    ).data.filter(entr => entr.entreprise.isEnabled);
    this.data.columns = [
      {
        name: "editable",
        sortField: "login",
        title: this.$t("externalUsers.login").toString(),
        property: "login",
        // username control condition : 8 alphanumeric characters
        regex: /^[A-Za-z0-9]{8}$/,
        regexCondition: UserRoleEnum.orderGiver
      },
      //{ name: 'editable', sortField: 'password', title: 'Password', property: 'password' },
      {
        name: "editable",
        sortField: "firstName",
        title: this.$t("externalUsers.firstName").toString(),
        property: "firstName",
      },
      {
        name: "editable",
        sortField: "lastName",
        title: this.$t("externalUsers.lastName").toString(),
        property: "lastName",
      },
      {
        name: "dropdown",
        sortField: "nameEntreprise",
        title: this.$t("externalUsers.entreprise").toString(),
        property: "idEntreprise",
        options: this.entreprises,
        valueField: "entreprise.id",
        textField: "translation.name",
        display: "nameEntreprise"
      },
      {
        name: "editable",
        sortField: "mail",
        title: this.$t("externalUsers.email").toString(),
        property: "mail",
        type: 'email'
      },
      {
        name: "switch",
        sortField: "isContact",
        title: this.$t("externalUsers.contact").toString(),
        property: "isContact",
      },
      {
        name: "dropdown",
        sortField: "nameRole",
        title: this.$t("externalUsers.role").toString(),
        property: "idRole",
        options: this.roles,
        valueField: "role.id",
        textField: "translation.name",
        display: "nameRole",
        default: 0,
        sideProperties: [{from: 'role.key', to:'keyRole'}]
      },
      {
        name: "switch",
        sortField: "isEnabled",
        title: this.$t("externalUsers.enabled").toString(),
        property: "isEnabled",
      },
    ];
  }
  public mounted() {
    
    this.loadElements();
 
   
  }
  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.loadElements();
    this.url = UserService.getTableUrl("externalUser", this.$i18n.locale);
  }
}
