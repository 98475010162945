var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bopmodel-setup mt-5",staticStyle:{"padding":"0 5%"},attrs:{"fluid":""}},[(_vm.canShowExportLoader)?_c('div',{staticClass:"bopmodel-loader d-flex justify-content-center mb-3 height-100vh "},[_c('b-spinner',{staticClass:"margin-auto",attrs:{"label":"Loading..."}})],1):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.$t("bopModelSetup.title")))]),_c('b-card',{staticClass:"default"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"site-group","label":_vm.$t('bopModelSetup.bopModelSelect'),"label-for":"selectBopModel-select"}},[_c('b-form-select',{attrs:{"id":"selectBopModel-select","options":_vm.bopModels,"text-field":"translation.name","value-field":"bopModel"},on:{"change":_vm.handleBopModelChange},model:{value:(_vm.selectedBopModel),callback:function ($$v) {_vm.selectedBopModel=$$v},expression:"selectedBopModel"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"rubrique-group","label":_vm.$t('bopModelSetup.rubriqueSelect'),"label-for":"rubrique-select"}},[_c('b-form-select',{attrs:{"id":"rubrique-select","options":_vm.rubriqueOptions},on:{"change":_vm.handleRubriqueChange},model:{value:(_vm.selectedRubrique),callback:function ($$v) {_vm.selectedRubrique=$$v},expression:"selectedRubrique"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[(_vm.tableRubrique === _vm.rubriques.OperationDiverse)?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('bopModelSetup.TypeTravaux')}},[_c('b-form-checkbox-group',{attrs:{"id":"typetravaux-group","options":_vm.typeTravaux,"name":"typetravaux","text-field":"name","value-field":"id"},on:{"change":_vm.handleTypeTravauxChange},model:{value:(_vm.selectedTypeTravaux),callback:function ($$v) {_vm.selectedTypeTravaux=$$v},expression:"selectedTypeTravaux"}})],1)],1):(
            _vm.tableRubrique === _vm.rubriques.CoefficientDimensions && _vm.tableBopModel.id
          )?_c('b-form-group',{attrs:{"id":"rubrique-group","label":_vm.$t('bopModelSetup.coefficientType'),"label-for":"coefficient-select"}},[_c('b-form-select',{attrs:{"id":"coefficient-select","options":_vm.coefficientTypes,"text-field":"name","value-field":"id"},on:{"change":_vm.handleCoefficientIdChange},model:{value:(_vm.selectedCoefficientId),callback:function ($$v) {_vm.selectedCoefficientId=$$v},expression:"selectedCoefficientId"}})],1):_vm._e()],1)],1)],1),(_vm.tableBopModel.id)?[((!_vm.tableRubrique || (_vm.tableRubrique === _vm.rubriques.CoefficientDimensions && !_vm.tableCoefficientId))  && _vm.exportBopModelUrl)?[_c('div',{staticClass:"d-flex mt-5 justify-content-end"},[_c('b-button',{attrs:{"variant":"secondary","disabled":!_vm.isCentralAdministrator},on:{"click":_vm.handleImport}},[_vm._v(" "+_vm._s(_vm.$t('bopModelSetup.importBopModel').toString())+" ")]),_c('b-button',{attrs:{"variant":"secondary","disabled":!_vm.isCentralAdministrator},on:{"click":_vm.exportBopModelUrl}},[_vm._v(" "+_vm._s(_vm.$t('bopModelSetup.exportBopModel').toString())+" ")])],1)]:_vm._e(),(
        _vm.tableRubrique === _vm.rubriques.OperationPrincipal ||
          _vm.tableRubrique === _vm.rubriques.OperationDiverse
      )?[_c('OperationsSetup',{key:(_vm.tableRubrique + "_" + (_vm.tableTypeTravaux.length) + "_" + (_vm.tableBopModel.id) + "_" + (_vm.$i18n.locale)),attrs:{"bopModelId":_vm.tableBopModel.id,"bopModelKey":_vm.tableBopModel.key,"operationKey":_vm.tableRubrique,"typeTravauxFilter":_vm.tableTypeTravaux}})]:(
        _vm.tableRubrique === _vm.rubriques.CoefficientDimensions && _vm.tableCoefficientId
      )?[(_vm.isNotType5Coefficient)?_c('CoefficientDimensionsSetup',{key:(_vm.tableCoefficientId + "_" + (_vm.$i18n.locale)),attrs:{"bopModelId":_vm.tableBopModel.id,"bopModelKey":_vm.tableBopModel.key,"coefficient-metadata":_vm.coefficientMetadata,"coefficientId":_vm.tableCoefficientId}}):(_vm.isType5Coefficient)?_c('CoefficientPerSiteSetup',{key:(_vm.tableCoefficientId + "_" + (_vm.$i18n.locale)),attrs:{"bopModelId":_vm.tableBopModel.id,"bopModelKey":_vm.tableBopModel.key,"coefficientId":_vm.tableCoefficientId}}):_vm._e()]:(_vm.tableRubrique === _vm.rubriques.Exceptions)?[_c('ExceptionSetup',{key:((_vm.tableBopModel.id) + "_" + (_vm.$i18n.locale)),attrs:{"bopModelId":_vm.tableBopModel.id,"bopModelKey":_vm.tableBopModel.key}})]:(_vm.tableRubrique === _vm.rubriques.ReferenceFourniture)?[_c('ReferenceFournitures',{key:((_vm.tableBopModel.id) + "_" + (_vm.$i18n.locale)),attrs:{"bopModelId":_vm.tableBopModel.id}})]:(_vm.tableRubrique === _vm.rubriques.ComplementsCoutsSupp)?[_c('ComplementsCoutsSuppAdministration',{key:((_vm.tableBopModel.id) + "_" + (_vm.$i18n.locale))})]:_vm._e()]:_vm._e(),_c('ImportBopModelModal',{ref:"importBopModelModal"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }