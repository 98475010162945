import apiProvider from "@/utils/api";
import { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
import BopArchive from "@/models/BopArchive";
import Bop from "@/models/Bop";

const ROOT_PATH = 'bopArchives';
class BopArchiveService {
    // public getTableUrl(lang:string): string {
    //     return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
    // }

     public getBopArchive(bopId: string): AxiosPromise<Bop> {
        return apiProvider.get(`${ROOT_PATH}/byBop${toUrl({bopId})}`);
     }
 
}

export default new BopArchiveService();