import { Guid } from "guid-typescript";

export default class MinimumFacturable {
    public id: string;
    public bopModelId: string;
    public entrepriseId: string;
    public typeTravauxId: string;
    public minimumPoints:number;
    constructor() {
        this.id = Guid.createEmpty().toString();
        this.bopModelId = "";
        this.entrepriseId = "";
        this.typeTravauxId = "";
        this.minimumPoints = 0;


    }
    copyConstructor(minimumFacturable:any):void{
        this.id = minimumFacturable.id;
        this.bopModelId = minimumFacturable.bopModelId;
        this.entrepriseId = minimumFacturable.entrepriseId;
        this.typeTravauxId = minimumFacturable.typeTravauxId;
        this.minimumPoints = minimumFacturable.minimumPoints;
        

    }
  }
  