import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'typetravaux';





class TypeTravauxService {
    // public getTableUrl(): string {
    //     return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
    // }

    public async listTypeTravaux(lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang})}`);
    }
    public async getTypeTravauxByKey(typeTravauxKey:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/${typeTravauxKey}${toUrl({lang})}`);
    }

    // public addSite(data: any): AxiosPromise {
    //     return apiProvider.put(`${rootPath}/${data.site.id}${toUrl({lang: lang})}`, data);
    // }
}

export default new TypeTravauxService();