export default {
  findByPath: function(obj: any, path: string): any {
    if (path != undefined) {
      var current = obj;
      path.split(".").forEach(function(p) {
        if(!current)return;
        current = current[p];
      });
      return current;
    } else {
      return undefined;
    }
  },
  setByPath: function(obj: any, path: string, value: any) {
    let i;
    let splittedPath = path.split(".");
    for (i = 0; i < splittedPath.length - 1; i++) {
      if (!obj[splittedPath[i]]) {
        obj[splittedPath[i]] = {};
      }
      obj = obj[splittedPath[i]];
    }
    obj[splittedPath[i]] = value;
  },
};
