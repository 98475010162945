import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers";
import MinimumFacturable from '@/models/MinimumFacturable';
const rootPath = 'minimumFacturables';
import  i18n  from '@/i18n';

class MinimumFacturableService {
  
    public async getByEntrepriseBopModelDomainApplication(bopModelId:string,entrepriseId:string,domainApplicationid:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({bopModelId,entrepriseId,domainApplicationid})}`);
    }
    public async save(minimumFacturable:MinimumFacturable): Promise<any> {
        return apiProvider.put(`${apiProvider.getUrl()}${rootPath}/${minimumFacturable.id}`,minimumFacturable);
    }
    
}

export default new MinimumFacturableService();