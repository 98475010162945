













































































import { Component, Vue ,Watch} from "vue-property-decorator";
import Entreprise from "@/models/Entreprise";
import MinimumFacturable from "@/models/MinimumFacturable";
import EntreprisesServices from "@/services/EntreprisesServices";
import TypeTravauxService from "@/services/TypeTravauxService";
import BopModelService from "@/services/BopModelService";
import MinimumFacturableService from "@/services/MinimumFacturableService";
import { Guid } from "guid-typescript";

@Component({
  components: {},
})
export default class Facturation extends Vue {
  private isMinimumPointsDisabled: boolean = true;
  private entreprises: Array<Object> = [];
  private typeTravaux: Array<Object> = [];
  private bopModels: Array<Object> = [];
  private selectedEntrepriseId: any = null;
  private selectedTypeTravauxId: any = null;
  private selectedBopModelId: any = null;
  private minimumFacturable: MinimumFacturable = new MinimumFacturable();

 
  private async save() {
    if (!this.isMinimumPointsDisabled) {
      try{
        const response = (await MinimumFacturableService.save(this.minimumFacturable))
        if(response.status === 200){
           this.$notify({
            group: "global",
            type: "success",
            title: this.$t("facturation.saveSuccessTitle").toString(),
            text: this.$t("facturation.saveSuccess").toString(),
          });
        }else{
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t("facturation.errorTitle").toString(),
            text: this.$t("facturation.uknownError").toString(),
        });
        }
      }catch{
        this.$notify({
            group: "global",
            type: "success",
            title: this.$t("facturation.errorTitle").toString(),
            text: this.$t("facturation.uknownError").toString(),
        });
      }
    }
  }
  private async loadMinimumFacturable() {
    this.isMinimumPointsDisabled =
      this.selectedEntrepriseId == null ||
      this.selectedTypeTravauxId == null ||
      this.selectedBopModelId == null;

    if (!this.isMinimumPointsDisabled) {
      let minimumFacturable: any = {};
      try {
        minimumFacturable = (await MinimumFacturableService.getByEntrepriseBopModelDomainApplication(
            this.selectedBopModelId,
            this.selectedEntrepriseId,
            this.selectedTypeTravauxId
          )
        ).data;
      } catch {
        minimumFacturable = {
          id : Guid.createEmpty().toString(),
          bopModelId: this.selectedBopModelId,
          entrepriseId: this.selectedEntrepriseId,
          typeTravauxId: this.selectedTypeTravauxId,
          minimumPoints: 0,
        };
      }
      this.minimumFacturable.copyConstructor(minimumFacturable);
    }
  }
  private async loadElements(){
this.entreprises = (await EntreprisesServices.getEntreprises(this.$i18n.locale)).data;
    this.typeTravaux = (await TypeTravauxService.listTypeTravaux(this.$i18n.locale)).data;
    this.bopModels = (await BopModelService.getBopModels(this.$i18n.locale)).data;
  }
  public created() {
    this.loadElements()
  }
  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.loadElements()
  }
}
