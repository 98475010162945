export  function toUrl  (params: any): string {
        return `?${new URLSearchParams(params).toString()}`;
}
export function roundNumber(number:number):number{
       return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function debounce(action:()=>any,debounceChecker:number|null|undefined,timeout:number = 300):number{
    if(debounceChecker){
        clearTimeout(debounceChecker);
    }
    return setTimeout(action,timeout);
}