



































import { Component, Vue, Watch } from "vue-property-decorator";
import AppHeader from "@/components/Global/AppHeader.vue";
import BopStatusService from "./services/BopStatusService";
import ExportService from "@/services/ExportService";

@Component({
  components: {
    AppHeader,
  },
})
export default class App extends Vue {
  private animation!: any;
  private showApp: boolean = false;
  
  private get isTranslationLoaded(): boolean {
    return this.$store.state.isTranslatinLoaded;
  }

  private get canShowMainComponent(): boolean {
    return this.isTranslationLoaded;
  }
  private beforeCreate() {
    window.addEventListener("beforeunload", this.confirmPageLeaving);
    this.animation = {
      enter(element) {
        let height = -element.clientHeight;

        return {
          translateY: [0, height],
          opacity: [1, 0],
        };
      },
      leave: {
        translateY: 0,
        opacity: 0,
      },
    };
  }
  private created() {
    if (this.$store.state.statuses.length === 0) {
      BopStatusService.getAllStatuses(this.$i18n.locale).then((response) => {
        this.$store.commit("changeStatuses", response.data);
      });
    }
  }
  private async confirmPageLeaving() {
    this.$store.commit("changeHasBeenModified", false);
  }

  private mounted() {
    // Fix mobile viewport height
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }
  errorCaptured(err, vm, info) {
    console.log(err);
    console.log(info);
    console.log(vm);
  }
}
