













































import { Component, Vue, Watch } from "vue-property-decorator";
import BopModelService from "@/services/BopModelService";
import { Guid } from "node_modules/guid-typescript/dist/guid";
import SitesServices from "@/services/SitesServices";
import Constants from "@/utils/Constants";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
  },
})
export default class BopModelsSiteConfiguration extends Vue {
  public data: Data = {
    columns: [
      { name: "checkbox", title: "", property: "bopModel.id" },
      {
        name: "editable",
        sortField: "translation.name",
        title: this.$t("bopAccess.bopModelName"),
        property: "translation.name",
        isNotEditable: true,
      },
    ],
    rows: [],
  };

  public sites: Array<any> = [];
  public selectedRows: Array<string> = [];
  public checkedRows: Array<string> = [];
  public selectedSite: any = null;
  private url: string = BopModelService.getTableUrl(this.$i18n.locale);
  public async save() {
    try {
      let response = await SitesServices.DeployBopModels(
        this.selectedSite.id,
        this.selectedRows
      );
      if (response.status === 200) {
        this.refresh();

        this.$notify({
          group: "global",
          type: "success",
          title: this.$t("bopAccess.saveSucessMessageTitle").toString(),
          text: this.$t("bopAccess.saveSucessMessageBody").toString(),
        });
      }
    } catch {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bopAccess.saveErrorMessageTitle").toString(),
        text: this.$t("bopAccess.saveErrorMessageBody").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    }
  }
  public refresh() {
    let siteResult: any = {};
    SitesServices.getTranslatedSite(
      this.selectedSite.id,
      this.$i18n.locale
    ).then((response: any) => {
      siteResult = response.data.site;
      let index: number = this.sites.findIndex(
        (s: any) => s.site.id === this.selectedSite.id
      );
      this.sites[index].site = siteResult;
      this.selectedSite = this.sites[index].site;
      this.$store.commit("changeHasBeenModified", false);

    });

    //(<any>this.$refs.table).refreshAPI();
  }

  public rowsSelectionChange(selectedRows: any) {
    this.selectedRows = selectedRows;
  }

  /**
   * Check the deployed bopmodels in the selected site
   */
  public checkDeployedBopModel() {
    this.$store.commit('changeIsFirstCheckBoxTableLoad',true);
    this.checkedRows = this.selectedSite.bopModels.map((bm: any) => bm.id);
    
  }
  private async loadElements() {
    const response = await SitesServices.getEnabledSites(this.$i18n.locale);
    if (response.status === 200) {
      this.sites = response.data;
    }
  }
  public mounted() {
    this.loadElements();
  }
  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.url = BopModelService.getTableUrl(this.$i18n.locale);
    this.loadElements();
    this.$store.commit("changeHasBeenModified", false);
  }
}
