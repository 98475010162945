


































import { Component, Vue, Watch } from 'vue-property-decorator';
import ImportService from '@/services/ImportService';
import {loadLanguageAsync} from "@/i18n";


@Component({
  components: {
    Modal: () => import('@/components/Shared/Modal.vue')
  },
})
export default class ImportLoadingModal extends Vue {
  private file = null;
  public showImportModal: boolean = false;
  private isLoadingImportModal: Boolean = false;
  private errorReport: string = "";

  public openImportModal() {
    this.showImportModal = true;
  }

  public closeImportModal() {
    if(!this.isLoadingImportModal) this.showImportModal = false;
  }

  private async validate() {
    if(this.file !== undefined && this.file != null) {
      const formData = new FormData();
      this.errorReport = "";
      formData.append("File", this.file || "");

      this.isLoadingImportModal = true;

      try {
        const result = await ImportService.importTranslations(formData);
  
        this.isLoadingImportModal = false;
        if(result?.status == 200) {
  
          if(result.data != "Success") {
            this.errorReport = result.data;
          } else {
            this.closeImportModal();
            this.$notify({
              group: "global",
              type: "success",
              title: this.$t("import.successfull.title").toString(),
              text: this.$t("import.successfull.description").toString(),
            });
            await loadLanguageAsync(this.$i18n.locale, true);
            this.$emit('handleImportSuccess');
          }
  
        }
      } catch (error) {
        this.isLoadingImportModal = false;
        this.$notify({
          group: "global",
          type: "error",
          title: this.$t("import.error.title").toString(),
          text: this.$t("import.error.description").toString(),
        });
      }
      this.showImportModal = false;
    }
  }
}
