













import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    Login: () => import('@/components/Auth/Login.vue'),
  },
})
export default class Auth extends Vue {}
