


















































import {Component, Vue} from 'vue-property-decorator';
import ImportService from '@/services/ImportService';


@Component({
  components: {
    Modal: () => import('@/components/Shared/Modal.vue')
  },
})
export default class ImportBopModelModal extends Vue {
  public showImportModal: boolean = false;
  private file = null;
  private isLoadingImportModal: Boolean = false;
  private errorReport: string = "";
  private isValidateDisabled: boolean = false;

  public openImportModal() {
    this.showImportModal = true;
  }

  public closeImportModal() {
    this.file = null;
    this.errorReport = "";
    this.isValidateDisabled = false;
    if (!this.isLoadingImportModal) this.showImportModal = false;
  }

  private async validate() {
    if (this.isValidateDisabled || !this.file) return;
    this.isValidateDisabled = true;
    const formData = new FormData();
    this.errorReport = "";
    formData.append("File", this.file || "");

    this.isLoadingImportModal = true;
    try {
      const result = await ImportService.importBopModel(formData);

      this.isLoadingImportModal = false;
      if (result?.status == 200) {

        if (result.data != "Success") {
          this.errorReport = result.data;
        } else {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t("import.bopModelSuccessful.title").toString(),
            text: this.$t("import.bopModelSuccessful.description").toString(),
          });
          this.closeImportModal();
        }
      }

    } catch (e) {
      this.isLoadingImportModal = false;
      let text = this.$t(
          "bopModelSetup.bopModelError.text"
      ).toString();
      if (e.response.status === 400) {
        if (e.response.data === "notValidFileFormat") {
          text = this.$t(
              "import.bopModelNotValidFormat"
          ).toString();
        }
      }
      this.$notify({
        group: "global",
        type: "error",
        title: this.$t("import.bopModelError.title").toString(),
        text,
      });
    } finally {
      this.isValidateDisabled = false;
    }

  }
}
